import React, { useState } from 'react'
import { graphql } from 'gatsby'
import { PageContextPropsType } from '../../i18n/i18n'
import Layout from '../components/Layout'
import FAQItem from '../components/FAQItem'
import SEO from '../components/SEO'
import * as SiteWideStyles from '../styles/SiteWide.css'
import * as styles from '../styles/Lp.css'
import {
  LandingPageData,
  Hero,
  Logo,
  Head,
  Appeal3Column,
  Appeal2Column,
  Appeal1Column,
  UsecaseContent,
  Usecase,
  VoiceContent,
  Voice,
  FaqContent,
  Faq,
  Issue,
  Appeal2Column2Row,
} from '../types/lp'

type LandingPageProps = {
  data: { newtLandingPage: LandingPageData }
} & PageContextPropsType

export default function LandingPage({ data, pageContext }: LandingPageProps) {
  const { newtLandingPage } = data
  const [currentQuestion, setCurrentQuestion] = useState<HTMLDivElement | null>(null)
  const slug = `/lp/${newtLandingPage.slug}/`
  const meta = newtLandingPage.meta
  const faqData: any = newtLandingPage.section.find((section: any) => section.type === 'FAQ_AREA')?.data
  const faqContents: FaqContent[] = faqData?.faqContents ? faqData.faqContents : []

  const HeroSection = ({ data }: { data: Hero['data'] }) => {
    const backgroundImage = data.heroBackgroundImage ? data.heroBackgroundImage.src : ''
    return (
      <section className={styles.Section + ' ' + styles.Hero} style={{ backgroundImage: `url(${backgroundImage})` }}>
        <div className={styles.Section_Inner}>
          <div className={styles.Hero_Copy} dangerouslySetInnerHTML={{ __html: data.heroShoulderCopy }}></div>
          <h1 className={styles.Hero_Title} dangerouslySetInnerHTML={{ __html: data.heroTitleCopy }}></h1>
          <ul className={styles.Hero_Buttons}>
            <li>
              <a href={data.heroButtonUrl1} className={SiteWideStyles.Button + ' ' + styles.Hero_PrimaryButton}>
                {data.heroButtonLabel1}
              </a>
              {data.heroLinkLabel1 &&
                (data.heroLinkUrl1 ? (
                  <a href={data.heroLinkUrl1} className={styles.Hero_ButtonsLink}>
                    {data.heroLinkLabel1}
                  </a>
                ) : (
                  <p className={styles.Hero_ButtonsLink}>{data.heroLinkLabel1}</p>
                ))}
            </li>
            {data.heroButtonLabel2 && (
              <li>
                <a href={data.heroButtonUrl2} className={SiteWideStyles.Button + ' ' + styles.Hero_SecondaryButton}>
                  {data.heroButtonLabel2}
                </a>
                {data.heroLinkLabel2 && (
                  <a href={data.heroLinkUrl2} className={styles.Hero_ButtonsLink}>
                    {data.heroLinkLabel2}
                  </a>
                )}
              </li>
            )}
          </ul>
        </div>
      </section>
    )
  }

  const LogoSection = ({ data }: { data: Logo['data'] }) => {
    return (
      <section className={styles.Section + ' ' + styles.Logo}>
        <div className={styles.Section_Inner}>
          <ul className={styles.Logo_List}>
            {data.logoImage.map((logoImage: { _id: string; src: string }) => (
              <li className={styles.Logo_ListItem} key={logoImage._id}>
                <img src={logoImage.src} alt="" width="140" height="60" className={styles.Logo_ListImage} />
              </li>
            ))}
          </ul>
        </div>
      </section>
    )
  }

  const HeadSection = ({ data }: { data: Head['data'] }) => {
    return (
      <section className={styles.Section + ' ' + styles.Head}>
        <div className={styles.Section_Inner}>
          <h2 className={styles.Head_Text} dangerouslySetInnerHTML={{ __html: data.headText }}></h2>
        </div>
      </section>
    )
  }

  const Appeal3ColumnSection = ({ data }: { data: Appeal3Column['data'] }) => {
    return (
      <section className={styles.Section + ' ' + styles.Point3}>
        <div className={styles.Section_Inner}>
          <h2 className={styles.Point3_Heading} dangerouslySetInnerHTML={{ __html: data.appeal3TitleCopy }}></h2>
          <ul className={styles.Point3_List}>
            <li className={styles.Point3_ListItem}>
              <img src={data.appeal3Image1.src} alt="" width="800" height="600" className={styles.Point3_ListImage} />
              <span className={styles.Point3_ListText} dangerouslySetInnerHTML={{ __html: data.appeal3Text1 }}></span>
            </li>
            <li className={styles.Point3_ListItem}>
              <img src={data.appeal3Image2.src} alt="" width="800" height="600" className={styles.Point3_ListImage} />
              <span className={styles.Point3_ListText} dangerouslySetInnerHTML={{ __html: data.appeal3Text2 }}></span>
            </li>
            <li className={styles.Point3_ListItem}>
              <img src={data.appeal3Image3.src} alt="" width="800" height="600" className={styles.Point3_ListImage} />
              <span className={styles.Point3_ListText} dangerouslySetInnerHTML={{ __html: data.appeal3Text3 }}></span>
            </li>
          </ul>
        </div>
      </section>
    )
  }

  const Appeal2ColumnSection = ({ data }: { data: Appeal2Column['data'] }) => {
    return (
      <section className={styles.Section + ' ' + styles.Point2}>
        <div className={styles.Section_Inner}>
          <h2 className={styles.Point2_Heading} dangerouslySetInnerHTML={{ __html: data.appeal2TitleCopy }}></h2>
          <ul className={styles.Point2_List}>
            <li className={styles.Point2_ListItem}>
              <img src={data.appeal2Image1.src} alt="" width="800" height="600" className={styles.Point2_ListImage} />
              <span className={styles.Point2_ListText} dangerouslySetInnerHTML={{ __html: data.appeal2Text1 }}></span>
            </li>
            <li className={styles.Point2_ListItem}>
              <img src={data.appeal2Image2.src} alt="" width="800" height="600" className={styles.Point2_ListImage} />
              <span className={styles.Point2_ListText} dangerouslySetInnerHTML={{ __html: data.appeal2Text2 }}></span>
            </li>
          </ul>
        </div>
      </section>
    )
  }

  const Appeal1ColumnSection = ({ data }: { data: Appeal1Column['data'] }) => {
    return (
      <section className={styles.Section + ' ' + styles.Point1}>
        <div className={styles.Section_Inner}>
          <div className={styles.Point1_Item}>
            <h2 className={styles.Point1_ItemHeading} dangerouslySetInnerHTML={{ __html: data.appeal1TitleCopy }}></h2>
            {data.appeal1Text && <p className={styles.Point1_ItemText} dangerouslySetInnerHTML={{ __html: data.appeal1Text }}></p>}
            <picture>
              {data.appeal1ImageSp && <source media="(max-width: 767px)" srcSet={data.appeal1ImageSp.src} width="375" height="1240" />}
              <img src={data.appeal1ImagePc.src} width="1440" height="900" alt="" className={styles.Point1_ItemImage} />
            </picture>
            {data.appeal1ButtonUrl && (
              <a
                href={data.appeal1ButtonUrl}
                rel="noreferrer noopener"
                target="_blank"
                className={SiteWideStyles.Button + ' ' + styles.Point1_Button}>
                {data.appeal1ButtonLabel}
              </a>
            )}
          </div>
        </div>
      </section>
    )
  }

  const UsecaseSection = ({ data }: { data: Usecase['data'] }) => {
    return (
      <section className={styles.Section + ' ' + styles.Usecase}>
        <div className={styles.Section_Inner}>
          {data.usecaseContents.map((content: UsecaseContent, index: number) => (
            <div className={styles.Usecase_Item} key={content._id}>
              <strong className={styles.Usecase_ItemCaption}>Case{index + 1}</strong>
              <h2 className={styles.Usecase_ItemHeading} dangerouslySetInnerHTML={{ __html: content.usecaseContentTitleCopy }}></h2>
              <div className={styles.Usecase_ItemInner}>
                <img src={content.usecaseContentImage.src} alt="" width="800" height="540" className={styles.Usecase_ItemImage} />
                <div className={styles.Usecase_ItemData}>
                  <div className={styles.Usecase_ItemText} dangerouslySetInnerHTML={{ __html: content.usecaseContentBody }}></div>
                </div>
              </div>
            </div>
          ))}
          <a
            href={data.usecaseButtonUrl}
            rel="noreferrer noopener"
            target="_blank"
            className={SiteWideStyles.Button + ' ' + styles.Usacase_Button}>
            {data.usecaseButtonLabel}
          </a>
        </div>
      </section>
    )
  }

  const VoiceSection = ({ data }: { data: Voice['data'] }) => {
    return (
      <section className={styles.Section + ' ' + styles.Voice}>
        <div className={styles.Section_Inner}>
          <h2 className={styles.Voice_Heading}>お客様の声</h2>
          {data.voiceContents.map((content: VoiceContent) => (
            <div className={styles.Voice_Item} key={content._id}>
              <img src={content.voiceContentImage.src} alt="" width="600" height="400" className={styles.Voice_ItemImage} />
              <div className={styles.Voice_ItemData}>
                <h2 className={styles.Voice_ItemHeading} dangerouslySetInnerHTML={{ __html: content.voiceContentName }}></h2>
                <div className={styles.Voice_ItemText} dangerouslySetInnerHTML={{ __html: content.voiceContentBody }}></div>
              </div>
            </div>
          ))}
          <a
            href={data.voiceButtonUrl}
            rel="noreferrer noopener"
            target="_blank"
            className={SiteWideStyles.Button + ' ' + styles.Voice_Button}>
            {data.voiceButtonLabel}
          </a>
        </div>
      </section>
    )
  }

  const IssueSection = ({ data }: { data: Issue['data'] }) => {
    return (
      <section className={styles.Section + ' ' + styles.Issue}>
        <div className={styles.Section_Inner}>
          <h2 className={styles.Issue_Heading} dangerouslySetInnerHTML={{ __html: data.issueTitleCopy }}></h2>
          <ul className={styles.Issue_List}>
            <li className={styles.Issue_ListItem}>
              <span className={styles.Issue_ListBadge}>お悩み1</span>
              <h3 className={styles.Issue_ListHeading} dangerouslySetInnerHTML={{ __html: data.issueTitleCopy1 }}></h3>
              <span className={styles.Issue_ListText} dangerouslySetInnerHTML={{ __html: data.issueText1 }}></span>
            </li>
            <li className={styles.Issue_ListItem}>
              <span className={styles.Issue_ListBadge}>お悩み2</span>
              <h3 className={styles.Issue_ListHeading} dangerouslySetInnerHTML={{ __html: data.issueTitleCopy2 }}></h3>
              <span className={styles.Issue_ListText} dangerouslySetInnerHTML={{ __html: data.issueText2 }}></span>
            </li>
            <li className={styles.Issue_ListItem}>
              <span className={styles.Issue_ListBadge}>お悩み3</span>
              <h3 className={styles.Issue_ListHeading} dangerouslySetInnerHTML={{ __html: data.issueTitleCopy3 }}></h3>
              <span className={styles.Issue_ListText} dangerouslySetInnerHTML={{ __html: data.issueText3 }}></span>
            </li>
          </ul>
        </div>
      </section>
    )
  }

  const Appeal2Column2RowSection = ({ data }: { data: Appeal2Column2Row['data'] }) => {
    return (
      <section className={styles.Section + ' ' + styles.Point2c2r}>
        <div className={styles.Section_Inner}>
          <h2 className={styles.Point2c2r_Heading} dangerouslySetInnerHTML={{ __html: data.appeal2Column2TitleCopy }}></h2>
          <ul className={styles.Point2c2r_List}>
            <li className={styles.Point2c2r_ListItem}>
              <img src={data.appeal2Column2Image1.src} alt="" width="800" height="600" className={styles.Point2c2r_ListImage} />
              <span className={styles.Point2c2r_ListText} dangerouslySetInnerHTML={{ __html: data.appeal2Column2Text1 }}></span>
            </li>
            <li className={styles.Point2c2r_ListItem}>
              <img src={data.appeal2Column2Image2.src} alt="" width="800" height="600" className={styles.Point2c2r_ListImage} />
              <span className={styles.Point2c2r_ListText} dangerouslySetInnerHTML={{ __html: data.appeal2Column2Text2 }}></span>
            </li>
            <li className={styles.Point2c2r_ListItem}>
              <img src={data.appeal2Column2Image3.src} alt="" width="800" height="600" className={styles.Point2c2r_ListImage} />
              <span className={styles.Point2c2r_ListText} dangerouslySetInnerHTML={{ __html: data.appeal2Column2Text3 }}></span>
            </li>
            <li className={styles.Point2c2r_ListItem}>
              <img src={data.appeal2Column2Image4.src} alt="" width="800" height="600" className={styles.Point2c2r_ListImage} />
              <span className={styles.Point2c2r_ListText} dangerouslySetInnerHTML={{ __html: data.appeal2Column2Text4 }}></span>
            </li>
          </ul>
        </div>
      </section>
    )
  }

  return (
    <Layout pageContext={pageContext}>
      <SEO title={meta.title} description={meta.description} path={slug} ogImageUrl={meta.ogImage.src} />
      <main className={styles.Container}>
        {newtLandingPage.section.map((section: any) => {
          switch (section.type) {
            case 'HERO':
              return <HeroSection data={section.data} key={section._id} />
            case 'LOGO':
              return <LogoSection data={section.data} key={section._id} />
            case 'Head':
              return <HeadSection data={section.data} key={section._id} />
            case 'APPEAL_3COLUMUN':
              return <Appeal3ColumnSection data={section.data} key={section._id} />
            case 'APPEAL_2COLUMN':
              return <Appeal2ColumnSection data={section.data} key={section._id} />
            case 'APPEAL_1COLUMUN':
              return <Appeal1ColumnSection data={section.data} key={section._id} />
            case 'USECASE_AREA':
              return <UsecaseSection data={section.data} key={section._id} />
            case 'VOICE_AREA':
              return <VoiceSection data={section.data} key={section._id} />
            case 'ISSUE_AREA':
              return <IssueSection data={section.data} key={section._id} />
            case 'APPEAL_2COLUMN_2ROW':
              return <Appeal2Column2RowSection data={section.data} key={section._id} />
            default:
              break
          }
        })}
        {faqData && (
          <section className={styles.Section + ' ' + styles.FAQ}>
            <div className={styles.Section_Inner}>
              <h2 className={styles.FAQ_Heading}>よくあるお問い合わせ</h2>
              <dl className={styles.FAQ_List}>
                {faqContents.map((content: FaqContent) => (
                  <FAQItem
                    question={content.faqContentQuestion}
                    currentQuestion={currentQuestion}
                    setCurrentQuestion={setCurrentQuestion}
                    key={content._id}>
                    <div dangerouslySetInnerHTML={{ __html: content.faqContentAnswer }}></div>
                  </FAQItem>
                ))}
              </dl>
            </div>
          </section>
        )}
      </main>
    </Layout>
  )
}

export const query = graphql`
  query ($_id: String!) {
    newtLandingPage(_id: { eq: $_id }) {
      _id
      pageName
      slug
      meta {
        title
        description
        ogImage {
          src
        }
      }
      section {
        ... on Hero {
          _id
          type
          data {
            heroShoulderCopy
            heroTitleCopy
            heroBackgroundImage {
              src
            }
            heroButtonLabel1
            heroButtonUrl1
            heroLinkLabel1
            heroLinkUrl1
            heroButtonLabel2
            heroButtonUrl2
            heroLinkLabel2
            heroLinkUrl2
          }
        }
        ... on Logo {
          _id
          type
          data {
            logoImage {
              _id
              src
            }
          }
        }
        ... on Head {
          _id
          type
          data {
            headText
          }
        }
        ... on Appeal3Column {
          _id
          type
          data {
            appeal3TitleCopy
            appeal3Image1 {
              src
            }
            appeal3Text1
            appeal3Image2 {
              src
            }
            appeal3Text2
            appeal3Image3 {
              src
            }
            appeal3Text3
          }
        }
        ... on Appeal2Column {
          _id
          type
          data {
            appeal2TitleCopy
            appeal2Image1 {
              src
            }
            appeal2Text1
            appeal2Image2 {
              src
            }
            appeal2Text2
          }
        }
        ... on Appeal1Column {
          _id
          type
          data {
            appeal1TitleCopy
            appeal1Text
            appeal1ImagePc {
              src
            }
            appeal1ImageSp {
              src
            }
            appeal1ButtonUrl
            appeal1ButtonLabel
          }
        }
        ... on Usecase {
          _id
          type
          data {
            usecaseContents {
              _id
              usecaseContentTitleCopy
              usecaseContentBody
              usecaseContentImage {
                src
              }
            }
            usecaseButtonLabel
            usecaseButtonUrl
          }
        }
        ... on Voice {
          _id
          type
          data {
            voiceContents {
              _id
              voiceContentImage {
                src
              }
              voiceContentName
              voiceContentBody
            }
            voiceButtonLabel
            voiceButtonUrl
          }
        }
        ... on Faq {
          _id
          type
          data {
            faqContents {
              _id
              faqContentQuestion
              faqContentAnswer
            }
          }
        }
        ... on Issue {
          _id
          type
          data {
            issueTitleCopy
            issueTitleCopy1
            issueText1
            issueTitleCopy2
            issueText2
            issueTitleCopy3
            issueText3
          }
        }
        ... on Appeal2Column2Row {
          _id
          type
          data {
            appeal2Column2TitleCopy
            appeal2Column2Image1 {
              src
            }
            appeal2Column2Text1
            appeal2Column2Image2 {
              src
            }
            appeal2Column2Text2
            appeal2Column2Image3 {
              src
            }
            appeal2Column2Text3
            appeal2Column2Image4 {
              src
            }
            appeal2Column2Text4
          }
        }
      }
    }
  }
`
