import React, { useState, useRef, useEffect, Dispatch, SetStateAction } from 'react'
import { motion, useAnimation } from 'framer-motion'
import classNames from 'classnames/bind'
import { colorVariants } from '../styles/vars.css'
import * as FAQItemstyles from '../styles/FAQItem.css'

type Props = {
  question: string
  children: React.ReactNode
  currentQuestion: HTMLDivElement | null
  setCurrentQuestion: Dispatch<SetStateAction<HTMLDivElement | null>>
}

const cx = classNames.bind(FAQItemstyles)

const FAQItem: React.VFC<Props> = (props) => {
  const [isOpen, setOpenState] = useState(false)
  const [closedHeight, setClosedHeight] = useState(82)
  const containerElement = useRef<HTMLDivElement>(null!)
  const questionElement = useRef<HTMLElement>(null!)

  const controls = useAnimation()

  const variants = {
    close: {
      height: `${closedHeight}px`,
      backgroundColor: colorVariants.neutral_0,
      transition: { duration: 0.14, ease: [0.165, 0.84, 0.44, 1.0] },
    },
    open: {
      height: 'auto',
      backgroundColor: colorVariants.neutral_4,
      transition: { delay: 0.2, duration: 0.28, ease: [0.895, 0.03, 0.685, 0.22] },
    },
  }

  const onClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!isOpen) props.setCurrentQuestion(event.currentTarget)
    else props.setCurrentQuestion(null)
  }

  useEffect(() => {
    setOpenState(props.currentQuestion === containerElement.current)
  }, [props.currentQuestion])

  useEffect(() => {
    if (isOpen) controls.start('open')
    else controls.start('close')
  }, [isOpen])

  useEffect(() => {
    setClosedHeight(questionElement.current.clientHeight + 52)

    window.addEventListener('resize', () => {
      setClosedHeight(questionElement.current.clientHeight + 52)
    })
  }, [])

  useEffect(() => {
    controls.start('close')
  }, [closedHeight])

  return (
    <motion.div
      animate={controls}
      variants={variants}
      ref={containerElement}
      onClick={onClick}
      className={cx('Container', isOpen && 'is-open')}
    >
      <dt ref={questionElement} className={cx('Question')}>
        {props.question}
        <div className={cx('SwitchIcon')} />
      </dt>

      <dd className={cx('Answer')}>{props.children}</dd>
    </motion.div>
  )
}

export default FAQItem
