import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import classNames from 'classnames/bind'
import { texts } from '../utils/texts'
import * as SiteWideStyles from '../styles/SiteWide.css'
import * as NextActionStyles from '../styles/NextAction.css'

const cx = classNames.bind(NextActionStyles)

const NextAction: React.VFC = () => {
  return (
    <aside className={cx('Container')}>
      <div className={cx('Inner')}>
        <h4 className={cx('Headline')}>{texts.top.nextAction.headline}</h4>

        <ul className={cx('Listing')}>
          <li className={cx('Listing_Item')}>
            <div className={cx('Listing_Header')}>
              <div className={cx('Listing_Icon')}>
                <StaticImage
                  src="../images/site-wide/material-icon.png"
                  width={40}
                  height={40}
                  alt={texts.top.nextAction.list.item1.caption}
                />
              </div>
              <div className={cx('Listing_Caption')}>{texts.top.nextAction.list.item1.caption}</div>
            </div>

            <a href="/ebook/" target="_blank" rel="noopener" className={cx(SiteWideStyles.Button, 'Button')}>
              {texts.top.nextAction.list.item1.button}
            </a>
          </li>

          <li className={cx('Listing_Item')}>
            <div className={cx('Listing_Header')}>
              <div className={cx('Listing_Icon')}>
                <StaticImage
                  src="../images/site-wide/contact-icon.png"
                  width={40}
                  height={40}
                  alt={texts.top.nextAction.list.item2.caption}
                />
              </div>
              <div className={cx('Listing_Caption')}>{texts.top.nextAction.list.item2.caption}</div>
            </div>

            <a href="https://karte.io/enterprise/" target="_blank" rel="noopener" className={cx(SiteWideStyles.Button, 'Button')}>
              {texts.top.nextAction.list.item2.button}
            </a>
          </li>

          <li className={cx('Listing_Item')}>
            <div className={cx('Listing_Header')}>
              <div className={cx('Listing_Icon')}>
                <StaticImage
                  src="../images/site-wide/video-conference-icon.png"
                  width={40}
                  height={40}
                  alt={texts.top.nextAction.list.item3.caption}
                />
              </div>
              <div className={cx('Listing_Caption')}>{texts.top.nextAction.list.item3.caption}</div>
            </div>

            <a
              href="https://app.dr.works/schedules/yutaotsuka/blocks_demo_90min"
              target="_blank"
              rel="noopener noreferrer"
              className={cx(SiteWideStyles.Button, 'Button')}
            >
              {texts.top.nextAction.list.item3.button}
            </a>
          </li>
        </ul>
      </div>
    </aside>
  )
}

export default NextAction
