import React from 'react'
import { Helmet } from 'react-helmet'
import { useMetadata } from '../utils/use-metadata'
import OGImage from '../images/site-wide/ogp-image-renewalv2.jpg'

export type Props = {
  title: string
  description: string
  path: string
  ogImageUrl?: string
  children?: React.ReactNode
}

const SEO: React.VFC<Props> = (props) => {
  const defaultMetadata = useMetadata().site.siteMetadata
  const canonical = `${defaultMetadata.siteUrl}${props.path}`
  const ogImageUrl = props.ogImageUrl ? props.ogImageUrl : `${defaultMetadata.siteUrl}${OGImage}`

  return (
    <Helmet>
      <html lang="ja" />
      <title>{props.title}</title>
      <meta name="description" content={props.description} />

      <meta property="og:url" content={canonical} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={props.title} />
      <meta property="og:description" content={props.description} />
      <meta property="og:image" content={ogImageUrl} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@KARTE_Blocks" />

      {props.children}
    </Helmet>
  )
}

export default SEO
