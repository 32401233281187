import React from 'react'
import { useLocation } from '@reach/router'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { GatsbyImage } from 'gatsby-plugin-image'
import classNames from 'classnames/bind'
import { texts } from '../utils/texts'
import * as SiteWideStyles from '../styles/SiteWide.css'
import * as BannerStyles from '../styles/Banner.css'

const cx = classNames.bind(BannerStyles)

const Banner: React.VFC = () => {
  const location = useLocation()
  const imageData = useStaticQuery(graphql`
    {
      mobileBg: file(relativePath: { eq: "site-wide/banner-bg_mobile.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 80, layout: FULL_WIDTH)
        }
      }
      desktopBg: file(relativePath: { eq: "site-wide/banner-bg_desktop.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 80, layout: FULL_WIDTH)
        }
      }
    }
  `)

  return (
    <section className={cx('Container', SiteWideStyles.Grid)}>
      <GatsbyImage
        image={imageData.mobileBg.childImageSharp.gatsbyImageData}
        alt=""
        className={cx('Cover', SiteWideStyles.DisplayMobileOnly)}
      />
      <GatsbyImage
        image={imageData.desktopBg.childImageSharp.gatsbyImageData}
        alt=""
        className={cx('Cover', SiteWideStyles.DisplayDesktopOnly)}
      />

      <div className={cx('PictureFrame')}>
        <StaticImage src="../images/site-wide/banner-picture.png" alt={texts.top.banner.alt} />
      </div>

      <div className={cx('Content')}>
        {location.pathname === '/lp/lpo/' ? (
          <h3 className={SiteWideStyles.Headline}>
            LPOツールは、KARTE Blocks
            <br />
            ノーコードのサイト運営を始めましょう
          </h3>
        ) : (
          <h3 className={SiteWideStyles.Headline}>{texts.top.banner.headline}</h3>
        )}
        <p className={cx('Lead')}>{texts.top.banner.lead}</p>

        <a
          href="https://blocks.karte.io/ebook/"
          target="_blank"
          rel="noopener"
          className={cx('Button', SiteWideStyles.Button, SiteWideStyles.LargeButton)}
        >
          {texts.top.banner.button}
        </a>
      </div>
    </section>
  )
}

export default Banner
